var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Optional captions" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCarouselCaption) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("Add captions to your slides easily with the prop")
        ]),
        _c("code", [_vm._v("caption")]),
        _c("span", [_vm._v("element within any")]),
        _c("code", [_vm._v("<b-carousel-slide>")]),
        _vm._v(". ")
      ]),
      _c(
        "b-carousel",
        { attrs: { id: "carousel-caption", controls: "", indicators: "" } },
        [
          _c("b-carousel-slide", {
            staticClass: "card-img-overlay",
            attrs: {
              "caption-html":
                "<span class='text-white'>First Slide Label</span>",
              text:
                "Donut jujubes I love topping I love sweet. Jujubes I love brownie gummi bears I love donut sweet chocolate. Tart chocolate marshmallow.Tart carrot cake muffin.",
              "img-src": require("@/assets/images/slider/08.jpg")
            }
          }),
          _c("b-carousel-slide", {
            staticClass: "card-img-overlay",
            attrs: {
              "caption-html":
                "<span class='text-white'>Second Slide Label</span>",
              text:
                "Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies. Cupcake cake I love cake danish carrot cake.",
              "img-src": require("@/assets/images/slider/09.jpg")
            }
          }),
          _c("b-carousel-slide", {
            staticClass: "card-img-overlay",
            attrs: {
              "caption-html":
                "<span class='text-white'>Third Slide Label</span>",
              text:
                "Pudding sweet pie gummies. Chocolate bar sweet tiramisu cheesecake chocolate cotton candy pastry muffin. Marshmallow cake powder icing.",
              "img-src": require("@/assets/images/slider/10.jpg")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }