var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Keyboard Option" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeKeyboard) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          " Whether the carousel should react to keyboard events. User prop "
        ),
        _c("code", [_vm._v("controls")]),
        _vm._v(" for keyboard event. ")
      ]),
      _c(
        "b-carousel",
        {
          attrs: {
            id: "carousel-interval",
            controls: "",
            indicators: "",
            interval: 400
          }
        },
        [
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/07.jpg") }
          }),
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/08.jpg") }
          }),
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/09.jpg") }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }