var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic Example" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCarouselBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          " The carousel is a slideshow for cycling through a series of content, built with CSS 3D transforms. It works with a series of images, text, or custom markup. "
        )
      ]),
      _c(
        "b-carousel",
        {
          attrs: {
            id: "carousel-example-generic",
            controls: "",
            indicators: ""
          }
        },
        [
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/01.jpg") }
          }),
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/02.jpg") }
          }),
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/03.jpg") }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }