var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Crossfade" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCarouselCrossfade) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Set the")]),
        _c("code", [_vm._v("<b-carousel>fade")]),
        _c("span", [_vm._v("prop to")]),
        _c("code", [_vm._v("true")]),
        _c("span", [
          _vm._v(
            "to animate slides with a fade transition instead of the default slide animation."
          )
        ])
      ]),
      _c(
        "b-carousel",
        {
          attrs: {
            id: "carousel-crossfade",
            controls: "",
            indicators: "",
            fade: ""
          }
        },
        [
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/01.jpg") }
          }),
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/02.jpg") }
          }),
          _c("b-carousel-slide", {
            attrs: { "img-src": require("@/assets/images/slider/03.jpg") }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }