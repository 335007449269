var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Programmatic slide control" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeProgrammaticSlide) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("The ")]),
        _c("code", [_vm._v("<b-carousel>")]),
        _c("span", [
          _vm._v(
            " instance provides several public methods for controlling sliding:"
          )
        ]),
        _c("code", [
          _vm._v("setSlide(index), next(), prev(), pause(), start()")
        ])
      ]),
      _c(
        "div",
        [
          _c(
            "b-carousel",
            {
              ref: "myCarousel",
              attrs: {
                id: "carousel-1",
                interval: 4000,
                controls: "",
                indicators: "",
                background: "#ababab",
                "img-width": "924",
                "img-height": "380"
              }
            },
            [
              _c("b-carousel-slide", {
                attrs: {
                  text:
                    "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                  "img-src": require("@/assets/images/slider/01.jpg")
                }
              }),
              _c("b-carousel-slide", {
                attrs: { "img-src": require("@/assets/images/slider/02.jpg") }
              }),
              _c("b-carousel-slide", {
                attrs: { "img-src": require("@/assets/images/slider/03.jpg") }
              }),
              _c("b-carousel-slide", {
                scopedSlots: _vm._u([
                  {
                    key: "img",
                    fn: function() {
                      return [
                        _c("img", {
                          staticClass: "d-block img-fluid w-100",
                          attrs: {
                            width: "924",
                            height: "380",
                            src: require("@/assets/images/slider/04.jpg"),
                            alt: "image slot"
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center mt-1"
            },
            [
              _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(113, 102, 240, 0.15)",
                          expression: "'rgba(113, 102, 240, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { variant: "outline-primary" },
                      on: { click: _vm.prev }
                    },
                    [_vm._v(" Pre ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(113, 102, 240, 0.15)",
                          expression: "'rgba(113, 102, 240, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { variant: "outline-primary" },
                      on: { click: _vm.start }
                    },
                    [_vm._v(" " + _vm._s(_vm.status ? "Start" : "Pause") + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(113, 102, 240, 0.15)",
                          expression: "'rgba(113, 102, 240, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { variant: "outline-primary" },
                      on: { click: _vm.next }
                    },
                    [_vm._v(" Next ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }